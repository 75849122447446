import { SPACING_CSS_VARS } from '@/lib/constants'
import { CamelCasedResumeTable, ResumeSpacing } from '@/lib/types'
import DOMPurify from 'dompurify'
import { MutableRefObject } from 'react'

export const applyResumeStyles = (
  resume: CamelCasedResumeTable,
  editorContentRef?: MutableRefObject<HTMLDivElement | null>,
): string | null => {
  const resumeDocumentElement = (
    editorContentRef?.current?.querySelector('[data-resume-document]') ||
    document.querySelector('[data-resume-document]')
  ) as HTMLElement | null

  if (!resumeDocumentElement) return null

  // Remove existing template and page size classes
  const existingClasses = Array.from(resumeDocumentElement.classList)
  existingClasses.forEach(className => {
    if (className.startsWith('template-') || className.startsWith('page-size-')) {
      resumeDocumentElement.classList.remove(className)
    }
  })

  // Apply template and page size classes
  resumeDocumentElement.classList.add(`template-${resume.templateName}`, `page-size-${resume.pageSize}`)

  // Apply text accent color
  if (resume.textAccentColor) {
    resumeDocumentElement.style.setProperty('--text-accent-color', resume.textAccentColor)
  }

  // Apply font family
  if (resume.fontFamily) {
    resumeDocumentElement.style.setProperty('--font-family', resume.fontFamily)
  }

  // Apply font size scale
  if (resume.fontSizeScale) {
    resumeDocumentElement.style.setProperty('--font-size-scale', resume.fontSizeScale.toString() || '1')
  }

  // Apply spacing variables
  if (resume.spacing) {
    const spacing = JSON.parse(JSON.stringify(resume.spacing)) as ResumeSpacing
    Object.entries(SPACING_CSS_VARS).forEach(([key, { cssVar }]) => {
      const value = spacing[key as keyof ResumeSpacing]
      if (value !== undefined) {
        const unit = key === 'lineHeight' ? '' : 'px'
        resumeDocumentElement.style.setProperty(cssVar, `${value}${unit}`)
      }
    })
  }

  // Sanitize and return HTML
  return DOMPurify.sanitize(resumeDocumentElement.outerHTML, {
    USE_PROFILES: { html: true },
  })
}
