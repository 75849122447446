import templateClassic from '@/assets/template-classic.png'
import templateModern from '@/assets/template-modern.png'
import { ResumeStructure } from "./types"

// This needs to be added so the new node doesn't collide with the previous node
export const NODE_POS_SPACING_CONSTANT = 2
export const NEW_HEADER_HEIGHT = 48

export const IN_PROD_ENV = import.meta.env.PROD

export const TEMPLATE_IMAGES = {
  modern: {
    imageSrc: templateModern,
    label: 'Modern',
  },
  classic: {
    imageSrc: templateClassic,
    label: 'Classic',
  },
}

export const PAGE_BREAK_PADDING_OFFSET = 6
export const PAGE_BREAK_PADDING_WIDTH = 50
export const TOTAL_PAGE_BREAK_PADDING = PAGE_BREAK_PADDING_OFFSET + PAGE_BREAK_PADDING_WIDTH

export const DEFAULT_EDITOR_VERTICAL_PADDING = 8

export const EXAMPLE_PARSER_OUTPUT_FORMAT: ResumeStructure = {
  header: {
    fullName: "John Smith",
    contactInfo: "(555) 555-5555 | [john@email.com](mailto: john@email.com) | [LinkedIn: johnsmith](https://linkedin.com/in/johnsmith)",
  },
  sections: [
    {
      type: "projects",
      entries: [
        {
          type: "projects",
          projectName: "[Project Name](https://project-url.com)",
          dateRange: "Jan 2023 - Present",
          leftParagraph: "React, Node.js",
          rightParagraph: "",
          bullets: [
            "Implemented feature X with [demo](https://demo-link.com)",
            "Another bullet point"
          ]
        }
      ]
    }
  ]
}

export const SPACING_CSS_VARS = {
  lineHeight: {
    label: 'Line Height',
    cssVar: '--resume-text-line-height',
  },
  verticalPadding: {
    label: 'Vertical',
    cssVar: '--resume-vertical-padding',
  },
  horizontalPadding: {
    label: 'Horizontal',
    cssVar: '--resume-horizontal-padding',
  },
  sectionPaddingTop: {
    label: 'Section',
    cssVar: '--resume-section-padding-top',
  },
  entryPaddingTop: {
    label: 'Entry',
    cssVar: '--resume-entry-padding-top',
  },
} as const

export const HYPERLINK_COLORS = {
  BLUE: '#0395de',
  BLACK: '#000000',
} as const

export const HYPERLINK_STYLES = {
  colors: {
    BLUE: '#0395de',
    BLACK: '#000000',
  },
  textDecoration: {
    UNDERLINE: 'underline',
    NONE: 'none',
  },
} as const
