/**
 * The Tooltip component is a reusable UI component that displays a tooltip with optional title and shortcut keys.
 *
 * Props:
 * - children (React.ReactNode): The content that triggers the tooltip.
 * - enabled (boolean, optional): Whether the tooltip is enabled. Defaults to true.
 * - title (string, optional): The title text to be displayed inside the tooltip.
 * - shortcut (string[], optional): An array of shortcut keys to be displayed inside the tooltip.
 * - tippyOptions (TippyProps, optional): Additional options to customize the Tippy.js tooltip behavior.
 *
 * The component uses the Tippy.js library for rendering the tooltip and applies Tailwind CSS classes for styling.
 * It uses React's useCallback for performance optimization, preventing unnecessary re-renders of the tooltip content.
 * The ShortcutKey component is used to render individual shortcut keys with appropriate styling.
 *
 * Note from Mark: this is a nice wrapper for helping us easily render visually consistent tooltips on our buttons and various UI elements!
 */

import Tippy from '@tippyjs/react/headless'
import { useCallback } from 'react'

import { cn } from '@/lib/utils'
import { TippyProps, TooltipProps } from './types'

const isMac = typeof window !== 'undefined' ? navigator.platform.toUpperCase().indexOf('MAC') >= 0 : false

const ShortcutKey = ({ children }: { children: string }): string => {
  if (children === 'Mod') {
    return isMac ? '⌘' : 'Ctrl'
  }

  if (children === 'Shift') {
    return '⇧'
  }

  if (children === 'Alt') {
    return isMac ? '⌥' : 'Alt'
  }

  return children
}

export const Tooltip = ({
  children,
  enabled = true,
  title,
  shortcut,
  content,
  tippyOptions = {},
}: TooltipProps): JSX.Element => {
  const renderTooltip = useCallback(
    (attrs: TippyProps) => (
      <div
        className={cn(
          'z-[999] flex items-center gap-1.5 rounded-sm bg-dark-900 px-2 py-1.5 text-xs font-medium text-dark-100 shadow-lg'
        )}
        tabIndex={-1}
        data-placement={attrs['data-placement']}
        data-reference-hidden={attrs['data-reference-hidden']}
        data-escaped={attrs['data-escaped']}
      >
        {content && <span>{content}</span>}
        {title && <span>{title}</span>}
        {(content || title) && shortcut && <span>•</span>}
        {shortcut && <span>{shortcut.map(shortcutKey => ShortcutKey({ children: shortcutKey })).join(' ')}</span>}
      </div>
    ),
    [shortcut, title, content]
  )

  if (enabled) {
    return (
      <Tippy
        delay={300}
        offset={[0, 8]}
        touch={false}
        zIndex={99999}
        appendTo={document.body}
        {...tippyOptions}
        render={renderTooltip}
      >
        <span className="w-full">{children}</span>
      </Tippy>
    )
  }

  return <>{children}</>
}

export default Tooltip
