import { ResumeSpacing } from '@/lib/types'
import { ReactNode, createContext, useContext, useState } from 'react'
import { StoreApi, createStore, useStore } from 'zustand'

type EditorState = {
  currentSpacingAdjustment: keyof ResumeSpacing | ''
  zoomLevel: number
  actions: {
    setCurrentSpacingAdjustment: (currentSpacingAdjustment: keyof ResumeSpacing | '') => void
    setZoomLevel: (zoomLevel: number) => void
  }
}
export const EditorStoreContext = createContext<StoreApi<EditorState> | null>(null)

type EditorStoreProviderProps = {
  children: ReactNode
  initialState?: Partial<EditorState>
}
export const EditorStoreProvider = ({ children, initialState }: EditorStoreProviderProps) => {
  const [store] = useState(() =>
    createStore<EditorState>(set => ({
      currentSpacingAdjustment: initialState?.currentSpacingAdjustment || '',
      zoomLevel: initialState?.zoomLevel || 1,
      actions: {
        setCurrentSpacingAdjustment: (currentSpacingAdjustment: keyof ResumeSpacing | '') =>
          set({ currentSpacingAdjustment }),
        setZoomLevel: (zoomLevel: number) => set({ zoomLevel }),
      },
    }))
  )
  return <EditorStoreContext.Provider value={ store }> { children } </EditorStoreContext.Provider>
}
const useEditorStore = <TResult = EditorState,>(selector: (state: EditorState) => TResult) => {
  const store = useContext(EditorStoreContext)
  if (!store) {
    throw new Error('useEditorStore must be used within a EditorStoreProvider')
  }
  return useStore(store, selector)
}

export const useCurrentSpacingAdjustment = () => useEditorStore(state => state.currentSpacingAdjustment)
export const useEditorStoreActions = () => useEditorStore(state => state.actions)
export const useZoomLevel = () => useEditorStore(state => state.zoomLevel)