import { NotFoundResume } from '@/components/app/SharedResume/NotFoundResume'
import { UnpublishedResume } from '@/components/app/SharedResume/UnpublishedResume'
import Loading from '@/components/ui/Loading'
import { useShareResumeQueries } from '@/hooks/react-query/use-share-resume-queries'
import { CamelCasedResumeTable } from '@/lib/types'
import { createContext, useContext } from 'react'

const SharedResumeContext = createContext<CamelCasedResumeTable | null>(null)

export const SharedResumeProvider = ({ children }: { children: React.ReactNode }) => {
  const { useSharedResumeQuery } = useShareResumeQueries()
  const sharedResume = useSharedResumeQuery()

  if (sharedResume.isPending) return <Loading />
  if (sharedResume.isError) {
    if (sharedResume.error.message === 'Resume not found') {
      return <NotFoundResume />
    }
    return <div>Error: {sharedResume.error?.message}</div>
  }

  if (!sharedResume.data.isPublic) {
    return <UnpublishedResume />
  }

  return <SharedResumeContext.Provider value={sharedResume.data}>{children}</SharedResumeContext.Provider>
}

export const useSharedResume = () => {
  const sharedResume = useContext(SharedResumeContext)
  if (!sharedResume) throw new Error('useSharedResume must be used within a SharedResumeProvider')

  return sharedResume
}
