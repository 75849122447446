import { PageSizeName } from '@/lib/types'
import { FontColors, FontFamilies, FontSizes } from './types'

export const PRIMARY_FONT_COLORS: FontColors = [
  // Neutral colors
  { label: 'Black', value: '#000000' },

  // Warm colors
  { label: 'Red', value: '#DC3522' },
  { label: 'Pink', value: '#EF4089' },

  // Cool colors
  { label: 'Sky Blue', value: '#0395DE' },
  { label: 'Emerald', value: '#00A388' },
  { label: 'Purple', value: '#D3A4F9' },
]

export const COLOR_PICKER_FONT_COLORS: FontColors = [
  // Monochrome
  { label: 'Gray1', value: '#1B2229' },
  { label: 'Gray2', value: '#202225' },
  { label: 'Gray3', value: '#204F61' },
  { label: 'Gray4', value: '#414141' },
  { label: 'Gray5', value: '#63686E' },
  { label: 'Gray6', value: '#687585' },
  { label: 'Gray7', value: '#8692A1' },
  { label: 'Gray8', value: '#8D9196' },
  { label: 'Gray9', value: '#9FA4A9' },
  { label: 'Gray10', value: '#A3B1C3' },

  // Blues (Dark to Light)
  { label: 'Blue1', value: '#010056' },
  { label: 'Blue2', value: '#020085' },
  { label: 'Blue4', value: '#12327D' },
  { label: 'Blue5', value: '#1D49B6' },
  { label: 'Blue6', value: '#2962F6' },
  { label: 'Blue7', value: '#3B85F7' },
  { label: 'Blue8', value: '#4A62E6' },
  { label: 'Blue9', value: '#495BF8' },
  { label: 'Blue3', value: '#0300F5' },
  { label: 'Blue10', value: '#4A90E2' },

  // Greens (Dark to Light)
  { label: 'Green1', value: '#2B4A10' },
  { label: 'Green2', value: '#2B6547' },
  { label: 'Green4', value: '#4A626C' },
  { label: 'Green3', value: '#3A83A1' },
  { label: 'Green6', value: '#5A0A2C' },
  { label: 'Green10', value: '#80AB33' },
  { label: 'Green11', value: '#92D541' },
  { label: 'Green12', value: '#9CB237' },
  { label: 'Green9', value: '#63DB52' },
  { label: 'Green5', value: '#53B7E0' },

  // Teals and Aquas
  { label: 'Teal1', value: '#600C67' },
  { label: 'Green7', value: '#5AC5D1' },
  { label: 'Green8', value: '#5BC78B' },

  // Purples (Dark to Light)
  { label: 'Purple3', value: '#5F0F83' },
  { label: 'Purple1', value: '#406E1D' },
  { label: 'Purple2', value: '#4F08F3' },
  { label: 'Purple4', value: '#831BF5' },
  { label: 'Purple5', value: '#8819B8' },
  { label: 'Purple6', value: '#B425F6' },
  { label: 'Purple7', value: '#DD30C9' },

  // Reds and Pinks (Dark to Light)
  { label: 'Red1', value: '#6F132A' },
  { label: 'Red2', value: '#AB2342' },
  { label: 'Red3', value: '#D83035' },
  { label: 'Red4', value: '#EA3323' },
  { label: 'Red5', value: '#EB573E' },
  { label: 'Pink1', value: '#EA339D' },

  // Oranges and Yellows (Dark to Light)
  { label: 'Orange1', value: '#7E5019' },
  { label: 'Orange2', value: '#B37227' },
  { label: 'Orange3', value: '#F09737' },
  { label: 'Yellow1', value: '#F0BF41' }
]

export type FontColorLabels = (typeof PRIMARY_FONT_COLORS)[number]['label']

export const FONT_FAMILIES: FontFamilies = [
  { label: 'Sans', value: 'Roboto' },
  { label: 'Serif', value: 'Times New Roman' },
  { label: 'LaTeX', value: 'Computer Modern' },
]

export const FONT_SIZES: FontSizes = [
  { label: 'XS', value: '0.8' },
  { label: 'S', value: '0.9' },
  { label: 'M', value: '1' },
  { label: 'L', value: '1.1' },
  { label: 'XL', value: '1.2' },
]


export const NODE_NAMES: Record<string, string> = {
  skillEntry: 'Skill',
  educationEntry: 'Education',
  experienceEntry: 'Experience',
  projectEntry: 'Project',
  skillSection: 'Skills',
  educationSection: 'Education',
  experienceSection: 'Experience',
  projectSection: 'Projects',
  certificationEntry: 'Certification',
  certificationSection: 'Certifications',
  honorEntry: 'Honor',
  honorSection: 'Honors',
  summarySection: 'Summary',
  involvementEntry: 'Involvement',
  involvementSection: 'Involvements',
}

export const DEFAULT_HIDDEN_NODE_NAMES = ['hiddenParagraph']

export const PAGE_DIMENSIONS: Record<PageSizeName, { width: number; height: number }> = {
  letter: {
    width: 816,
    height: 1056,
  },
  a4: {
    width: 793.7,
    height: 1122.5,
  },
} as const

export const PAGE_SIZE_INFO: Record<PageSizeName, { label: string; description: string }> = {
  letter: {
    label: 'Letter',
    description: 'U.S. & Canada',
  },
  a4: {
    label: 'A4',
    description: 'Other Countries',
  },
} as const

export const getPageDimensions = (pageSize: PageSizeName) => PAGE_DIMENSIONS[pageSize]