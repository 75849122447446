import { DEFAULT_EDITOR_VERTICAL_PADDING, NEW_HEADER_HEIGHT } from '@/lib/constants'
import { CamelCasedResumeTable, PageSizeName } from '@/lib/types'
import { useEffect, useState } from 'react'
import { getPageDimensions } from '../constants'
import { useEditorStoreActions, useZoomLevel } from '../context/EditorStoreContext'

export const ZOOM_LEVELS = [0.5, 0.7, 0.85, 1, 1.25, 1.5, 2]

export const useZoom = (resume: CamelCasedResumeTable) => {
  const zoomLevel = useZoomLevel()
  const { setZoomLevel } = useEditorStoreActions()
  const [isFitMode, setIsFitMode] = useState(false)

  const calculateFitZoom = () => {
    const EXTRA_PADDING = 10
    const resumeHeight = getPageDimensions(resume.pageSize as PageSizeName).height
    const availableHeight = window.innerHeight - NEW_HEADER_HEIGHT - EXTRA_PADDING - (DEFAULT_EDITOR_VERTICAL_PADDING * 2)
    return availableHeight / resumeHeight
  }

  const handleFitToHeight = () => {
    const fitZoom = calculateFitZoom()
    setZoomLevel(fitZoom)
    setIsFitMode(true)
  }

  const handleZoomIn = () => {
    if (isFitMode) {
      const nextZoom = ZOOM_LEVELS.find(zoom => zoom > zoomLevel)
      if (nextZoom) {
        setZoomLevel(nextZoom)
      }
    } else {
      const currentIndex = ZOOM_LEVELS.indexOf(zoomLevel)
      if (currentIndex < ZOOM_LEVELS.length - 1) {
        setZoomLevel(ZOOM_LEVELS[currentIndex + 1])
      }
    }
    setIsFitMode(false)
  }

  const handleZoomOut = () => {
    if (isFitMode) {
      const nextZoom = [...ZOOM_LEVELS].reverse().find(zoom => zoom < zoomLevel)
      if (nextZoom) {
        setZoomLevel(nextZoom)
      }
    } else {
      const currentIndex = ZOOM_LEVELS.indexOf(zoomLevel)
      if (currentIndex > 0) {
        setZoomLevel(ZOOM_LEVELS[currentIndex - 1])
      }
    }
    setIsFitMode(false)
  }

  const handleResetZoom = () => {
    setZoomLevel(1)
    setIsFitMode(false)
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && !e.shiftKey) {
        if (e.key === '=' || e.key === '-') {
          e.preventDefault()
          if (e.key === '=') {
            handleZoomIn()
          } else {
            handleZoomOut()
          }
        } else if (e.key === '0') {
          e.preventDefault()
          handleFitToHeight()
        } else if (e.key === '1') {
          e.preventDefault()
          handleResetZoom()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [zoomLevel, isFitMode, resume.pageSize])

  // Update fit zoom when window is resized
  useEffect(() => {
    if (!isFitMode) return

    const handleResize = () => {
      const fitZoom = calculateFitZoom()
      setZoomLevel(fitZoom)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isFitMode])

  // Update fit zoom when page size changes
  useEffect(() => {
    if (!isFitMode) return

    const fitZoom = calculateFitZoom()
    setZoomLevel(fitZoom)
  }, [isFitMode, resume.pageSize])

  return {
    zoomLevel,
    setZoomLevel,
    handleZoomIn,
    handleZoomOut,
    handleResetZoom,
    handleFitToHeight,
    isFitMode,
    setIsFitMode,
    ZOOM_LEVELS,
  }
} 