import { CamelCasedUserProfileTable } from '@/lib/types'
import { toCamelCase } from '@/lib/utils'
import supabase from '@/lib/utils/supabase'
import { queryOptions, skipToken, useQuery } from '@tanstack/react-query'

const NOT_FOUND_ERROR_CODE = 'PGRST116'
export async function fetchUserProfile(userId: string): Promise<CamelCasedUserProfileTable | null> {
  const { data, error } = await supabase
    .from('user_profiles')
    .select('*')
    .eq('id', userId)
    .single()

  if (error) {
    // Check if the error is a "not found" error
    if (error.code === NOT_FOUND_ERROR_CODE) {
      return null
    }
    // Throw other unexpected errors
    throw error
  }

  return toCamelCase(data)
}

export const queryFactory = {
  userProfile: (userId: string | undefined) =>
    queryOptions({
      queryKey: ['user_profile', userId],
      queryFn: userId ? () => fetchUserProfile(userId) : skipToken,
      staleTime: Infinity,
    }),
}

export const useUserProfileQueries = () => {
  const useUserProfileQuery = (userId: string | undefined) => {
    return useQuery(queryFactory.userProfile(userId))
  }

  return { useUserProfileQuery }
}

export default useUserProfileQueries 
