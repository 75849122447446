/*
 * Helpers vs. Utils:
 * - Helpers: Specific to a given project. They are not generally reusable across different projects. For example, functions dealing with the editor in this project.
 * - Utils: Generic functions that accomplish abstract tasks and are reusable across projects. For example, functions in the lodash library.
 */

import cssStyles from '@/styles/resumes.css?inline'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { PageSizeName } from '../types'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function removePxFromString(value: string): string {
  return value.replace('px', '')
}

type CamelCase<S extends string> = S extends `${infer P}_${infer R}` ? `${P}${Capitalize<CamelCase<R>>}` : S

export const toCamelCase = <T extends Record<string, any>>(
  obj: T
): { [K in keyof T as K extends string ? CamelCase<K> : K]: T[K] } => {
  const camelCaseObj: any = {}
  for (const key in obj) {
    const camelCaseKey = key.replace(/_([a-z])/g, (_, char) => char.toUpperCase())
    camelCaseObj[camelCaseKey] = obj[key]
  }
  return camelCaseObj
}

type SnakeCase<S extends string> = S extends `${infer P}${infer R}`
  ? `${P extends Capitalize<P> ? '_' : ''}${Lowercase<P>}${SnakeCase<R>}`
  : S

export const toSnakeCase = <T extends Record<string, any>>(
  obj: T
): { [K in keyof T as K extends string ? SnakeCase<K> : K]: T[K] } => {
  const snakeCaseObj: any = {}
  for (const key in obj) {
    const snakeCaseKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
    snakeCaseObj[snakeCaseKey] = obj[key]
  }
  return snakeCaseObj
}

export const addStylesToHtml = (htmlContent: string, pageSize: PageSizeName) => {
  return `
      <!DOCTYPE html>
      <html lang="en">
        <style>
          @page {
            size: ${pageSize};
            margin: 0;
          }
          ${cssStyles}
        </style>
        ${htmlContent}
      </html>
    `
}
