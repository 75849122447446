import { Button } from '@/components/ui/Button'
import { Icon } from '@/components/ui/Icon'
import { Menu } from '@/components/ui/Menu'
import { Tooltip } from '@/components/ui/Tooltip'
import { CamelCasedResumeTable } from '@/lib/types'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { useZoom, ZOOM_LEVELS } from '../hooks/use-zoom'

interface ZoomDropdownProps {
  className?: string
  resume: CamelCasedResumeTable
}

export const ZoomDropdown = ({ className, resume }: ZoomDropdownProps) => {
  const { zoomLevel, setZoomLevel, handleFitToHeight, isFitMode, setIsFitMode } = useZoom(resume)

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Button variant="inline" className={className}>
          <span className="text-center text-sm">{isFitMode ? 'Fit' : `${Math.round(zoomLevel * 100)}%`}</span>
          <Icon name="ChevronDown" />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Menu.Content type="dropdown" onCloseAutoFocus={e => e.preventDefault()}>
          <Menu.Item type="dropdown" onClick={handleFitToHeight}>
            <Tooltip shortcut={['Mod', '0']} tippyOptions={{ placement: 'right', offset: [0, 15] }}>
              <span>Fit</span>
            </Tooltip>
          </Menu.Item>
          <Dropdown.Separator className="my-0.5 h-px rounded bg-dark-700" />
          {ZOOM_LEVELS.map(zoom => (
            <Menu.Item
              key={zoom}
              type="dropdown"
              onClick={() => {
                setZoomLevel(zoom)
                setIsFitMode(false)
              }}
            >
              <span>{Math.round(zoom * 100)}%</span>
            </Menu.Item>
          ))}
        </Menu.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  )
}
