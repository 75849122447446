import { Button } from '@/components/ui/Button'
import { JoobaLogo } from '@/components/ui/JoobaLogo'
import { CamelCasedResumeTable } from '@/lib/types'
import { cn } from '@/lib/utils'
import { ZoomDropdown } from '../ResumeEditor/components/ZoomDropdown'
import { useDownloadResumePDF } from '../ResumeEditor/hooks/use-download-resume-pdf'

interface SharedResumeHeaderProps {
  fileName: string
  isScrolling?: boolean
  isMobile?: boolean
  resume: CamelCasedResumeTable
  resumeDocumentHtml: string | null
}

export const SharedResumeHeader = ({
  fileName,
  isScrolling,
  isMobile,
  resume,
  resumeDocumentHtml,
}: SharedResumeHeaderProps) => {
  const { downloadResumePDF, isDownloading } = useDownloadResumePDF(resume, resumeDocumentHtml || resume.contentHtml)

  return (
    <div
      className={cn(
        'relative z-10 flex h-14 items-center justify-between border-b border-transparent bg-light-50 px-4 py-2 transition-[border-color,box-shadow]',
        isScrolling &&
          'border-light-300 after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:right-0 after:h-3 after:translate-y-full after:bg-header-shadow'
      )}
    >
      <div className="flex items-center gap-4">
        <JoobaLogo type="wordmark" size="sm" />
        {!isMobile && <ZoomDropdown className="text-light-900" resume={resume} />}
      </div>

      {!isMobile && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <span className="text-sm font-medium text-light-900">{fileName}</span>
        </div>
      )}

      <div className="flex items-center gap-2">
        <Button variant="primary" onClick={downloadResumePDF} isLoading={isDownloading}>
          Download
        </Button>
      </div>
    </div>
  )
}

export default SharedResumeHeader
