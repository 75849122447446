import { cn } from '@/lib/utils'
import React from 'react'

export type ButtonVariant = 'primary' | 'inline' | 'secondary'

export type ButtonProps = {
  variant?: ButtonVariant
  className?: string
  withOutline?: boolean
  isLoading?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'primary', children, className, withOutline = true, isLoading = false, ...rest }, ref) => {
    const buttonClassName = cn(
      'flex items-center justify-center text-md font-medium rounded-md whitespace-nowrap transition px-3 py-2',
      'relative select-none',

      variant === 'primary' &&
        cn(
          'border-t border-r border-l border-primary-200 bg-gradient-primary-500 px-3 py-2 shadow-sm text-white text-sm',
          withOutline && 'outline outline-1 outline-primary-700',
          'hover:bg-gradient-primary-600',
          'focus:ring-2 focus:ring-primary-300 focus:ring-offset-2',
          'disabled:opacity-50 disabled:cursor-not-allowed'
        ),

      variant === 'inline' &&
        cn(
          'text-light-900',
          ' hover:bg-light-100',
          'focus:ring-2 focus:ring-light-200 focus:ring-offset-2',
          'disabled:opacity-50 disabled:cursor-not-allowed'
        ),

      variant === 'secondary' &&
        cn(
          'border border-light-400 bg-white text-sm font-medium text-gray-900',
          'hover:bg-light-50',
          'focus:ring-2 focus:ring-light-200 focus:ring-offset-2',
          'disabled:opacity-50 disabled:cursor-not-allowed'
        ),

      isLoading && 'cursor-wait',
      className
    )

    const loaderClassName = cn(
      'h-1.5 w-1.5 animate-bounce rounded-full',
      variant === 'secondary' || variant === 'inline' ? 'bg-light-700' : 'bg-white'
    )

    return (
      <button ref={ref} className={buttonClassName} disabled={isLoading || rest.disabled} {...rest}>
        <span className={cn('flex items-center gap-1', isLoading && 'opacity-0')}>{children}</span>
        {isLoading && (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="flex items-center gap-1">
              <div className={cn(loaderClassName, '[animation-delay:-0.3s]')}></div>
              <div className={cn(loaderClassName, '[animation-delay:-0.15s]')}></div>
              <div className={loaderClassName}></div>
            </div>
          </div>
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'
