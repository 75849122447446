import { checkWaitlistStatus, isApproved, WaitlistSignupResponse } from '@/lib/waitlist'
import confetti from 'canvas-confetti'

export const isValidEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email)
}

export const getNormalizedEmail = (email: string) => {
  const normalizedEmail = email.toLowerCase()

  const [local, domain] = normalizedEmail.split('@')
  const normalizedLocal = local.split('+')[0]  // Only remove subaddressing

  return `${normalizedLocal}@${domain}`
}

export const triggerConfetti = () => {
  const end = Date.now() + 3 * 1000 // 3 seconds
  const colors = ['#a786ff', '#fd8bbc', '#eca184', '#f8deb1']

  const frame = () => {
    if (Date.now() > end) return

    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      startVelocity: 60,
      origin: { x: 0, y: 0.5 },
      colors: colors,
    })
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      startVelocity: 60,
      origin: { x: 1, y: 0.5 },
      colors: colors,
    })

    requestAnimationFrame(frame)
  }

  frame()
}

export async function getWaitlistData(email: string): Promise<WaitlistSignupResponse | null> {
  try {
    const data = await checkWaitlistStatus(email)
    return data
  } catch (error) {
    return null
  }
}

export function isApprovedOnWaitlist(waitlistData: WaitlistSignupResponse | null): boolean {
  if (!waitlistData) return false
  return isApproved(waitlistData)
}
