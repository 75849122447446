import { JoobaLogo } from '@/components/ui/JoobaLogo'
import { Link } from 'react-router-dom'

export const SharedErrorHeader = () => {
  return (
    <header className="flex h-14 items-center px-4 py-2">
      <Link to="/" className="flex items-center gap-2">
        <JoobaLogo type="wordmark" />
      </Link>
    </header>
  )
}

export default SharedErrorHeader
