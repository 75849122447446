import heroImageFaded from '@/assets/jooba-hero-image-faded.png'
import mobileHeroImageFaded from '@/assets/jooba-mobile-hero-image-faded.png'
import { AnimatePresence, motion } from 'framer-motion'
import posthog from 'posthog-js'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { WaitlistSignupResponse, checkWaitlistStatus, isApproved, signupForWaitlist } from '../../lib/waitlist'
import { Button } from '../ui/Button'
import { Input } from '../ui/Input'
import { JoobaLogo } from '../ui/JoobaLogo'
import { triggerConfetti } from './helpers'

// Animation constants
const ANIMATION_CONFIG = {
  animation: {
    initial: {
      opacity: 0,
      y: 20,
      filter: 'blur(8px)',
    },
    animate: {
      opacity: 1,
      y: 0,
      filter: 'blur(0px)',
    },
    exit: {
      opacity: 0,
      y: -20,
      filter: 'blur(8px)',
      transition: { duration: 0.2 },
    },
  },
  timing: {
    initialLoad: {
      duration: 0.5,
      delays: {
        header: 0.1,
        pill: 0.3,
        title: 0.4,
        subtitle: 0.5,
        content: 0.6,
        hero: 1,
      },
    },
    stateTransition: {
      duration: 0.2,
      delays: {
        title: 0,
        subtitle: 0.03,
        content: 0.06,
      },
    },
  },
} as const

// Animation helper
const getTransition = (isInitialLoad: boolean, type: 'title' | 'subtitle' | 'content') => ({
  duration: isInitialLoad
    ? ANIMATION_CONFIG.timing.initialLoad.duration
    : ANIMATION_CONFIG.timing.stateTransition.duration,
  delay: isInitialLoad
    ? ANIMATION_CONFIG.timing.initialLoad.delays[
        type === 'title' ? 'title' : type === 'subtitle' ? 'subtitle' : 'content'
      ]
    : ANIMATION_CONFIG.timing.stateTransition.delays[type],
})

interface HeroContentProps {
  title: string
  subtitle: React.ReactNode
  children?: React.ReactNode
  signupResponse: WaitlistSignupResponse | null
  isVerified: boolean
  onAnimationComplete?: () => void
}

function HeroContent({ title, subtitle, children, signupResponse, isVerified, onAnimationComplete }: HeroContentProps) {
  const isInitialLoad = !signupResponse && !isVerified

  return (
    <div className="max-w-5xl text-center">
      <AnimatePresence mode="wait">
        <motion.h1
          key={title}
          initial={ANIMATION_CONFIG.animation.initial}
          animate={ANIMATION_CONFIG.animation.animate}
          exit={ANIMATION_CONFIG.animation.exit}
          transition={getTransition(isInitialLoad, 'title')}
          onAnimationComplete={onAnimationComplete}
          className="mx-auto inline-block text-[28px] font-black leading-none text-light-900 sm:text-[32px] md:text-[48px] lg:leading-tight xl:leading-normal"
        >
          {title}
        </motion.h1>
      </AnimatePresence>

      <AnimatePresence mode="wait">
        <motion.div
          key={typeof subtitle === 'string' ? subtitle : 'subtitle'}
          initial={ANIMATION_CONFIG.animation.initial}
          animate={ANIMATION_CONFIG.animation.animate}
          exit={ANIMATION_CONFIG.animation.exit}
          transition={getTransition(isInitialLoad, 'subtitle')}
          className="mx-auto max-w-lg"
        >
          <p className="mt-3 text-lg text-light-800">{subtitle}</p>
        </motion.div>
      </AnimatePresence>

      {children && (
        <AnimatePresence mode="wait">
          <motion.div
            key="content"
            initial={ANIMATION_CONFIG.animation.initial}
            animate={ANIMATION_CONFIG.animation.animate}
            exit={ANIMATION_CONFIG.animation.exit}
            transition={getTransition(isInitialLoad, 'content')}
          >
            {children}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
}

function HeroImage() {
  return (
    <>
      {/* Desktop Hero Image */}
      <motion.div
        key="desktop-hero"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20, transition: { duration: 0.2 } }}
        transition={{ duration: 0.7, delay: ANIMATION_CONFIG.timing.initialLoad.delays.hero }}
        className="pointer-events-none mt-auto hidden w-full select-none justify-center md:flex"
      >
        <img
          src={heroImageFaded}
          alt="Jooba's professional resume builder interface showing template selection and easy editing features"
          className="w-full max-w-6xl rounded-t-lg shadow-thumbnail-hover"
        />
      </motion.div>

      {/* Mobile Hero Image */}
      <motion.div
        key="mobile-hero"
        initial={{ opacity: 0, x: 100, rotate: -10 }}
        animate={{ opacity: 1, x: 0, rotate: -10 }}
        exit={{ opacity: 0, x: 100, rotate: -10, transition: { duration: 0.2 } }}
        transition={{ duration: 0.7, delay: ANIMATION_CONFIG.timing.initialLoad.delays.hero }}
        className="pointer-events-none absolute right-0 top-80 md:hidden"
      >
        <div className="relative h-[80vw]">
          <img
            src={mobileHeroImageFaded}
            alt="Mobile view of Jooba's resume builder showing intuitive touch-friendly interface"
            className="absolute -right-10 h-full w-auto max-w-none rounded-lg object-cover shadow-thumbnail-hover"
          />
        </div>
      </motion.div>
    </>
  )
}

export function LandingPage() {
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [signupResponse, setSignupResponse] = useState<WaitlistSignupResponse | null>(null)
  const [hasCopied, setHasCopied] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [shouldTriggerConfetti, setShouldTriggerConfetti] = useState(false)
  const [referralCode, setReferralCode] = useState<string | null>(null)

  useEffect(() => {
    const verified = searchParams.toString().includes('verified=true')
    if (verified) {
      setIsVerified(true)
      triggerConfetti()
    }
  }, [searchParams])

  useEffect(() => {
    const refId = searchParams.get('ref_id')
    if (refId) {
      setReferralCode(refId)
    }
  }, [searchParams])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!email) {
      toast.error('Please enter your email address', {
        position: window.innerWidth < 768 ? 'top-center' : 'bottom-right',
      })
      return
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      toast.error('Please enter a valid email address', {
        position: window.innerWidth < 768 ? 'top-center' : 'bottom-right',
      })
      return
    }

    setIsLoading(true)
    try {
      const status = await checkWaitlistStatus(email)

      if (status) {
        setSignupResponse(status)
        return
      }

      const response = await signupForWaitlist(email)
      setSignupResponse(response)
      setShouldTriggerConfetti(true)
    } catch (error: any) {
      toast.error(error.message || 'Something went wrong. Please try again.', {
        position: window.innerWidth < 768 ? 'top-center' : 'bottom-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(signupResponse?.referral_link || '')
    posthog.capture('clicked_copy_referral_link')
    setHasCopied(true)
    setTimeout(() => setHasCopied(false), 2000)
  }

  const handleShareOnLinkedIn = () => {
    posthog.capture('clicked_share_on_linkedin')
    const text = encodeURIComponent(
      'Join me and over a thousand users who use Jooba for their resume. Sign up with my link below:'
    )
    const url = encodeURIComponent(signupResponse?.referral_link || '')
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&text=${text}`, '_blank')
  }

  const handleShareOnTwitter = () => {
    posthog.capture('clicked_share_on_x')
    const text = encodeURIComponent(
      'Join me and over a thousand users who use Jooba for their resume. Sign up with my link below:'
    )
    const url = encodeURIComponent(signupResponse?.referral_link || '')
    window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}`, '_blank')
  }

  const handleAnimationComplete = () => {
    if (shouldTriggerConfetti) {
      triggerConfetti()
      setShouldTriggerConfetti(false)
    }
  }

  const handleReset = () => {
    setEmail('')
    setSignupResponse(null)
    setIsVerified(false)
    setShouldTriggerConfetti(false)
    setHasCopied(false)
    // Clear the URL params if any
    window.history.replaceState({}, '', '/')
  }

  const renderContent = () => {
    if (isVerified) {
      return (
        <HeroContent
          title="Your email is verified!"
          subtitle={
            <>
              We'll notify you as soon as your account is ready.
              <br />
              In the meantime, you can get earlier access by referring friends to join the waitlist.
            </>
          }
          signupResponse={signupResponse}
          isVerified={isVerified}
        />
      )
    }

    if (!signupResponse) {
      return (
        <HeroContent
          title="The simplest way to create your resume"
          subtitle="Say goodbye to Docs, Word & Overleaf. Meet Jooba — the free, intuitive resume builder designed to get you hired."
          signupResponse={signupResponse}
          isVerified={isVerified}
        >
          <div className="mx-auto w-full max-w-md">
            <form onSubmit={handleSubmit} className="mt-8 flex flex-col items-center gap-2 pb-8">
              <div className="flex w-full gap-2">
                <Input
                  type="email"
                  placeholder="Your email address"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className="flex-1"
                  name="email"
                  autoComplete="email"
                />
                <Button type="submit" variant="primary" isLoading={isLoading} className="min-w-[120px]">
                  Get Early Access
                </Button>
              </div>
              <p className="text-xs text-light-800">Join the waitlist to get early access</p>
            </form>
          </div>
        </HeroContent>
      )
    }

    if (isApproved(signupResponse)) {
      return (
        <HeroContent
          title="You're already approved!"
          subtitle="You have access to Jooba! Sign in or create your account using the buttons in the top right."
          signupResponse={signupResponse}
          isVerified={isVerified}
        />
      )
    }

    return (
      <HeroContent
        title="You're on the waitlist!"
        subtitle={
          <>
            Interested in priority access? Refer your friends!
            <br />
            The more friends that join, the sooner you'll get access.
          </>
        }
        signupResponse={signupResponse}
        isVerified={isVerified}
        onAnimationComplete={handleAnimationComplete}
      >
        <div className="mx-auto w-full max-w-md">
          <div className="mt-8 flex flex-col items-center gap-6">
            <div className="flex w-full gap-4">
              <Button variant="secondary" className="flex-1" onClick={handleShareOnLinkedIn}>
                Share on LinkedIn
              </Button>
              <Button variant="secondary" className="flex-1" onClick={handleShareOnTwitter}>
                Share on X (Twitter)
              </Button>
            </div>

            <div className="flex w-full flex-col gap-3">
              <p className="text-sm font-medium text-light-800">Or use your unique referral link:</p>
              <div className="flex w-full gap-2">
                <Input
                  readOnly
                  value={signupResponse?.referral_link || ''}
                  className="flex-1"
                  onClick={e => (e.target as HTMLInputElement).select()}
                />
                <Button variant="primary" className="min-w-[120px]" onClick={handleCopyLink}>
                  {hasCopied ? 'Copied!' : 'Copy'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </HeroContent>
    )
  }

  return (
    <div className="relative flex min-h-[calc(100dvh)] flex-col bg-light-50">
      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: ANIMATION_CONFIG.timing.initialLoad.delays.header }}
        className="relative z-10 px-6 pt-4 sm:px-8 sm:pt-8 md:px-12 lg:px-16 xl:px-24"
      >
        <div className="flex w-full items-center justify-between">
          <button onClick={handleReset} className="cursor-pointer">
            <JoobaLogo type="wordmark" size="md" />
          </button>
          <div className="flex items-center gap-4">
            <Link to="/login">
              <Button variant="inline">Login</Button>
            </Link>
            <Link to="/signup">
              <Button variant="inline">Sign up</Button>
            </Link>
          </div>
        </div>
      </motion.div>

      {referralCode && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: ANIMATION_CONFIG.timing.initialLoad.delays.header + 0.1 }}
          className="relative z-10 mx-6 mt-4 sm:mx-auto sm:max-w-lg"
        >
          <div className="rounded-lg border border-primary-300 bg-primary-50 px-3 py-2 text-center text-xs font-medium text-light-900 sm:px-4 sm:py-3 sm:text-sm">
            You've been invited to Jooba! <span className="hidden sm:inline">You're using referral code: </span>
            <span className="sm:hidden">Ref code: </span>
            {referralCode}
          </div>
        </motion.div>
      )}

      {/* Main Content */}
      <div className="relative flex flex-1 flex-col overflow-hidden md:px-12 lg:px-16 xl:px-24">
        <div className="relative z-10 mt-8 flex flex-col items-center px-6 md:mt-12 md:px-0">
          {/* Private Beta Pill */}
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: ANIMATION_CONFIG.timing.initialLoad.delays.pill }}
            className="mb-8 flex h-6 items-center justify-center rounded-full border border-light-400 bg-light-200 px-4 shadow-thumbnail md:h-7 md:px-8"
          >
            <span className="text-xs font-medium leading-none text-light-800 md:text-sm">Jooba Private Beta</span>
          </motion.div>

          <AnimatePresence mode="wait">{renderContent()}</AnimatePresence>
        </div>
        {/* Hero Image */}
        <AnimatePresence mode="wait">{!signupResponse ? <HeroImage /> : null}</AnimatePresence>
      </div>
    </div>
  )
}
