import { PageSizeName } from '@/lib/types'
import { applyResumeStyles } from '@/lib/utils/resume-html'
import { useEffect, useState } from 'react'
import { getPageDimensions } from '../ResumeEditor/constants'
import { EditorStoreProvider } from '../ResumeEditor/context/EditorStoreContext'
import { SharedResumeProvider, useSharedResume } from './context/SharedResumeContext'
import { SharedResumeContentDesktop } from './SharedResumeContentDesktop'
import { SharedResumeContentMobile } from './SharedResumeContentMobile'
import { SharedResumeHeader } from './SharedResumeHeader'
import { UnpublishedResume } from './UnpublishedResume'

const SharedResumeView = () => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [editorContentWidth, setEditorContentWidth] = useState(0)
  const [editorContentHeight, setEditorContentHeight] = useState(0)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [resumeDocumentHtml, setResumeDocumentHtml] = useState<string | null>(null)
  const [hasInitialHtml, setHasInitialHtml] = useState(false)

  const sharedResume = useSharedResume()

  // If the resume is not public, show the unpublished state
  if (!sharedResume.isPublic) {
    return <UnpublishedResume />
  }

  // Update content dimensions when page size changes
  useEffect(() => {
    const dimensions = getPageDimensions(sharedResume.pageSize as PageSizeName)
    setEditorContentWidth(dimensions.width)
    setEditorContentHeight(dimensions.height)
  }, [sharedResume.pageSize])

  // Handle window resize and mobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Call once on mount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // First, set the initial HTML content
  useEffect(() => {
    setResumeDocumentHtml(sharedResume.contentHtml)
    setHasInitialHtml(true)
  }, [sharedResume.contentHtml])

  // Then, once the HTML is rendered, apply styles
  useEffect(() => {
    if (!hasInitialHtml) return

    // Small delay to ensure the DOM has been updated
    const timeoutId = setTimeout(() => {
      const html = applyResumeStyles(sharedResume)
      if (html) setResumeDocumentHtml(html)
    }, 0)

    return () => clearTimeout(timeoutId)
  }, [hasInitialHtml, sharedResume])

  // Re-apply styles when template or page size changes
  useEffect(() => {
    if (!hasInitialHtml) return

    const timeoutId = setTimeout(() => {
      const html = applyResumeStyles(sharedResume)
      if (html) setResumeDocumentHtml(html)
    }, 0)

    return () => clearTimeout(timeoutId)
  }, [hasInitialHtml, sharedResume.templateName, sharedResume.pageSize])

  return (
    <div className="h-screen-safe flex flex-col overflow-hidden bg-light-50 md:h-screen">
      <SharedResumeHeader
        fileName={sharedResume.fileName}
        isScrolling={isScrolling}
        isMobile={isMobile}
        resume={sharedResume}
        resumeDocumentHtml={resumeDocumentHtml}
      />

      {isMobile ? (
        <SharedResumeContentMobile
          resumeDocumentHtml={resumeDocumentHtml}
          editorContentWidth={editorContentWidth}
          editorContentHeight={editorContentHeight}
          isScrolling={isScrolling}
          setIsScrolling={setIsScrolling}
        />
      ) : (
        <SharedResumeContentDesktop
          editorContentWidth={editorContentWidth}
          editorContentHeight={editorContentHeight}
          isScrolling={isScrolling}
          setIsScrolling={setIsScrolling}
          resumeDocumentHtml={resumeDocumentHtml}
          resume={sharedResume}
        />
      )}
    </div>
  )
}

// This is the root component that provides the shared resume context
export const SharedResume = () => {
  return (
    <SharedResumeProvider>
      <EditorStoreProvider>
        <SharedResumeView />
      </EditorStoreProvider>
    </SharedResumeProvider>
  )
}

export default SharedResume
