import { DEFAULT_EDITOR_VERTICAL_PADDING } from '@/lib/constants'
import { useEffect, useState } from 'react'

interface SharedResumeContentMobileProps {
  resumeDocumentHtml: string | null
  editorContentWidth: number
  editorContentHeight: number
  isScrolling: boolean
  setIsScrolling: (isScrolling: boolean) => void
}

export function SharedResumeContentMobile({
  resumeDocumentHtml,
  editorContentWidth,
  editorContentHeight,
  setIsScrolling,
}: SharedResumeContentMobileProps) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const MOBILE_PADDING = 32

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const scale = editorContentWidth > 0 ? Math.max(0, (windowWidth - MOBILE_PADDING) / editorContentWidth) : 1

  return (
    <div
      className="flex flex-1 flex-col items-center overflow-auto py-2"
      onScroll={e => {
        const target = e.target as HTMLDivElement
        setIsScrolling(target.scrollTop > DEFAULT_EDITOR_VERTICAL_PADDING)
      }}
    >
      {resumeDocumentHtml && (
        <div
          style={{
            width: editorContentWidth * scale,
            height: editorContentHeight * scale,
            margin: '0 auto',
          }}
        >
          <div
            className="rounded-md bg-white shadow-page"
            data-shared-resume
            style={{
              width: editorContentWidth,
              transform: `scale(${scale})`,
              transformOrigin: 'top left',
            }}
            dangerouslySetInnerHTML={{ __html: resumeDocumentHtml }}
          />
        </div>
      )}
    </div>
  )
}
