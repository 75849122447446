import { cn } from '@/lib/utils'
import * as ContextMenu from '@radix-ui/react-context-menu'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { motion } from 'framer-motion'
import React from 'react'

type MenuType = typeof ContextMenu | typeof DropdownMenu

interface MenuContentProps {
  type: 'context' | 'dropdown'
  className?: string
  children: React.ReactNode
}

const MenuContent = React.forwardRef<HTMLDivElement, MenuContentProps & React.ComponentProps<MenuType['Content']>>(
  ({ type, className, children, ...props }, ref) => {
    const MenuType = type === 'context' ? ContextMenu : DropdownMenu

    return (
      <MenuType.Content sideOffset={8} className="z-[9999]" ref={ref} {...props}>
        <motion.div
          initial={{ opacity: 0, scale: 0.95, y: -4 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.95, y: -4 }}
          transition={{
            duration: 0.15,
            ease: 'easeOut',
          }}
          className={cn(
            'flex flex-col rounded-lg border-t border-gray-700 bg-dark-900 p-2 text-gray-100 shadow-lg',
            className
          )}
        >
          {children}
        </motion.div>
      </MenuType.Content>
    )
  }
)

interface MenuItemProps {
  type: 'context' | 'dropdown'
  children: React.ReactNode
  onSelect?: () => void
  className?: string
  destructive?: boolean
}

const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps & React.ComponentProps<MenuType['Item']>>(
  ({ type, children, onSelect, className, destructive, ...props }, ref) => {
    const MenuType = type === 'context' ? ContextMenu : DropdownMenu

    const itemClass = cn(
      'flex items-center px-2 py-1.5 text-sm font-medium w-full rounded-md select-none cursor-pointer outline-none text-dark-200 gap-2 hover:bg-dark-800 hover:text-dark-100',
      destructive && 'hover:bg-danger-1000 text-danger-500 hover:text-danger-500',
      className
    )

    return (
      <MenuType.Item ref={ref} className={itemClass} onSelect={onSelect} {...props}>
        {children}
      </MenuType.Item>
    )
  }
)

export const Menu = {
  Content: MenuContent,
  Item: MenuItem,
}
