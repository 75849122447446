import { DEFAULT_EDITOR_VERTICAL_PADDING } from '@/lib/constants'
import { CamelCasedResumeTable } from '@/lib/types'
import { cn } from '@/lib/utils'
import { useZoom } from '../ResumeEditor/hooks/use-zoom'

interface SharedResumeContentDesktopProps {
  editorContentWidth: number
  editorContentHeight: number
  isScrolling: boolean
  setIsScrolling: (isScrolling: boolean) => void
  resumeDocumentHtml: string | null
  resume: CamelCasedResumeTable
}

export const SharedResumeContentDesktop = ({
  setIsScrolling,
  resumeDocumentHtml,
  resume,
}: SharedResumeContentDesktopProps) => {
  const { zoomLevel } = useZoom(resume)

  return (
    <div className="relative flex-1 overflow-hidden">
      <div
        className="h-full w-full overflow-auto px-4"
        onScroll={e => {
          const target = e.target as HTMLDivElement
          setIsScrolling(target.scrollTop > DEFAULT_EDITOR_VERTICAL_PADDING)
        }}
      >
        <div
          className="flex min-h-full items-start justify-center"
          style={{ padding: `${DEFAULT_EDITOR_VERTICAL_PADDING}px 0` }}
        >
          {resumeDocumentHtml && (
            <div
              className={cn('rounded-md bg-white shadow-page', zoomLevel > 1.0 ? 'h-fit' : 'h-full')}
              data-shared-resume
              style={{
                transform: `scale(${zoomLevel})`,
                transformOrigin: 'top center',
              }}
              dangerouslySetInnerHTML={{ __html: resumeDocumentHtml }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
