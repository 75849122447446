import { Icon } from '@/components/ui/Icon'
import { SharedErrorHeader } from './SharedErrorHeader'

export const UnpublishedResume = () => {
  return (
    <div className="flex h-[100dvh] flex-col bg-light-50">
      <SharedErrorHeader />
      <div className="flex flex-1 flex-col items-center justify-center gap-6 px-6 py-8 text-center md:gap-8 md:px-8">
        <Icon name="Lock" className="h-10 w-10 text-light-700 md:h-12 md:w-12" />
        <div className="space-y-2">
          <h1 className="text-xl font-semibold text-light-900 md:text-2xl">This resume is no longer public</h1>
          <p className="text-sm text-light-800 md:text-base">
            The owner has made this resume private or it has been unpublished.
          </p>
        </div>
      </div>
    </div>
  )
}

export default UnpublishedResume
