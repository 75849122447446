import { cn } from '@/lib/utils'
import { icons } from 'lucide-react'
import { memo } from 'react'

export type IconProps = {
  name: keyof typeof icons
  className?: string
  strokeWidth?: number
  fill?: string
  color?: string
}

export const Icon = memo(({ name, className, strokeWidth, fill, color }: IconProps) => {
  const IconComponent = icons[name]

  if (!IconComponent) {
    return null
  }

  return (
    <IconComponent
      className={cn('h-5 w-5 p-0.5', className)}
      strokeWidth={strokeWidth || 2}
      fill={fill || 'none'}
      color={color}
    />
  )
})

Icon.displayName = 'Icon'
