import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { Suspense, lazy } from 'react'
import { Outlet, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'
import { Toaster } from 'sonner'
import { SharedResume } from './components/app/SharedResume/SharedResume'
import { LandingPage } from './components/site/LandingPage'
import Loading from './components/ui/Loading'
import { UserContextProvider } from './context/UserContext'
import { queryFactory } from './hooks/react-query/use-resume-queries'
import { privacyPolicyHtml } from './lib/privacy-policy'
import supabase from './lib/utils/supabase'
import PostHogPageView from './pageview'

const LazyLoginPage = lazy(() => import('./components/site/LoginPage'))
const LazySignupPage = lazy(() => import('./components/site/SignupPage'))
const LazyNotFoundPage = lazy(() => import('./components/site/NotFoundPage'))

const LazyHomePage = lazy(() => import('./components/app/HomePage/HomePage'))
const LazyResumeEditor = lazy(() => import('./components/app/ResumeEditor/ResumeEditor'))
const LazyErrorPage = lazy(() => import('./components/site/ErrorPage'))

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    element: (
      <>
        <Outlet />
        <PostHogPageView />
      </>
    ),
    children: [
      { path: '/', element: <LandingPage /> },
      { path: '/login', element: <LazyLoginPage /> },
      { path: '/signup', element: <LazySignupPage /> },
      {
        path: '/home',
        element: <LazyHomePage />,
        errorElement: <LazyErrorPage />,
        loader: async ({ request }) => {
          const {
            data: { user },
          } = await supabase.auth.getUser()

          if (!user) {
            const redirectTo = new URL(request.url).pathname
            return redirect(`/login?redirectTo=${redirectTo}`)
          }
          const allResumesQuery = queryFactory.resumes(user.id)
          return queryClient.ensureQueryData(allResumesQuery)
        },
      },
      {
        path: '/resume/:resumeId',
        element: <LazyResumeEditor />,
        errorElement: <LazyErrorPage />,
        loader: async ({ params, request }) => {
          const {
            data: { user },
          } = await supabase.auth.getUser()

          if (!user) {
            const redirectTo = new URL(request.url).pathname
            return redirect(`/login?redirectTo=${redirectTo}`)
          }

          const singleResumeQuery = queryFactory.singleResume(user.id, params.resumeId)
          return queryClient.ensureQueryData(singleResumeQuery)
        },
      },
      {
        path: '/s/:shareId',
        element: <SharedResume />,
      },
      {
        path: '*',
        element: <LazyNotFoundPage />,
      },
      {
        path: '/privacy-policy',
        element: (
          <div className="mx-auto max-w-5xl px-4 py-8">
            <div dangerouslySetInnerHTML={{ __html: privacyPolicyHtml }} />
          </div>
        ),
      },
    ],
  },
])

posthog.init(import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: 'identified_only',
  capture_pageview: false,
})

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Disable Sentry in development
  enabled: import.meta.env.PROD,
  // Tracing
  tracesSampleRate: import.meta.env.PROD ? 1.0 : 0.0, //  Capture 100% of the transactions in production only
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: import.meta.env.PROD ? 0.1 : 0.0, // This sets the sample rate at 10% in production only
  replaysOnErrorSampleRate: import.meta.env.PROD ? 1.0 : 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur in production only
})

export default function App() {
  return (
    <PostHogProvider client={posthog}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Toaster richColors className="Toaster" />
        <UserContextProvider>
          <Suspense fallback={<Loading />}>
            <RouterProvider router={router} />
            {/* <IntercomMessenger /> */}
          </Suspense>
        </UserContextProvider>
      </QueryClientProvider>
    </PostHogProvider>
  )
}
